<template>
  <div id="signup">         

  <section class="container mt-5">

    <div class="row justify-content-center">
    <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">

      <TextInput
        name="firstName"
        type="text"
        label="First Name"
      />
      <TextInput
        name="lastName"
        type="text"
        label="Last Name"
      />
      <TextInput
        name="username"
        type="text"
        label="Username"
      />
      <TextInput
        name="email"
        type="email"
        label="E-mail"
      />
      <TextInput
        name="phoneNumber"
        type="tel"
        label="Phone Number"
      />
      <TextInput
        name="dob"
        type="date"
        label="Date of birth DD/MM/YYYY"
        tooltip="Date of birth is needed to confirm age restriction."
        placeholder="dd/mm/yyyy"
      />
      <TextInput
        name="password"
        type="password"
        label="Password"
      />
      <TextInput
        name="confirm_password"
        type="password"
        label="Confirm Password"
      />
      <div class="row mb-3">
        <div class="col-6">                
                <div class="form-group form-check">
                    <Field name="acceptTerms" type="checkbox" id="acceptTerms" :value="true" class="form-check-input" :class="{ 'is-invalid': errors.acceptTerms }" />
                    <label for="acceptTerms" class="form-check-label"><a href="#">Accept Terms & Conditions</a></label>
                </div>

                  <div class="form-group form-check">
                    <Field name="acceptPromos" type="checkbox" v-model="acceptPromos" class="form-check-input" />
                    <label for="acceptPromos" class="form-check-label"><a href="#">Receive promotional emails</a></label>
                </div>
        </div>
        <div class="col-6 text-end">
          <router-link v-bind:to="'/forgot-password'">Forgot Password</router-link>
        </div>
      </div>
      <button type="submit" class="btn btn-custom">Sign Up</button>

      <div class="login-social row d-block justify-content-center mt-5">
        
        <ul class="d-flex justify-content-center">
          <li class="list-group-item">
            <!-- google	 -->
            <button v-on:click="loginWithGoogle" type="button" class="login-with-google-btn" >
              Sign in with Google
            </button>
          </li>
          <li class="list-group-item">
            <button v-on:click="loginWithFacebook" type="button" class="login-with-facebook-btn" >
              Sign in with Facebook
            </button>
          </li>
        </ul>

      </div>

    </Form>

    </div>
  </section>

  <Footer/>
  </div>
</template>

<script>
import Api from '../scripts/api'

import Footer from '../components/global/Footer'

import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import TextInput from "../components/Form/TextInput";


export default {
    components: {
        Footer,
        Form,
        TextInput,
        Field
    },
    watch: {
      account: {
        immediate: true,
        handler (newAccount) {
          if(newAccount && newAccount.account) {
           this.$router.push('/');
         }
        }
      }
    },
    props: {
      account: Object
    },
    data() {
      return {
        acceptPromos: false
      }
    },

    setup() {
      const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
      var schema = Yup.object().shape({
        firstName: Yup.string().required('Please enter first name.'),
        lastName: Yup.string().required('Please enter last name.'),
        username: Yup.string().required('Make your username confidential.'),
        email: Yup.string().email().required('Please enter a valid e-mail.'),
        phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Please enter your phone number.'), 
        dob: Yup.date().required('Please enter your date of birth.'),
        password: Yup.string().min(6).required('Please enter a strong password.'),
        confirm_password: Yup.string()
          .required('Please confirm your password.')
          .oneOf([Yup.ref("password")], "Passwords do not match."),
        acceptTerms: Yup.bool()
        .required('Please make sure to accept terms & conditions.')
      });

      return {
        schema,
      };
    },
    methods: {
      onSubmit: function(values) {
        this.register(values.email, values.password, values.firstName, values.lastName, values.username, values.dob, values.phoneNumber, values.terms);
      },
      register: async function(email, password, firstName, lastName, username, dob, phoneNumber) {
        var result = await Api.register(email, password, firstName, lastName, username, dob, phoneNumber);
        if(result.status == 409) return this.$toast.error('Email address already exists.')
        if(result.status == 410) return this.$toast.error('You must be at least 18 years old to make an account.')

        if(result.status == 200) {

          if(this.acceptPromos)
            Api.mailchimpSignup(email, firstName, lastName);

          this.$parent.$parent.setAccount(await result.json());
          this.$router.push('/');
        }
      },
      loginWithGoogle: async function() {
        var redirectionurl = 'https://competitionmania.co.uk/api/account/login/google';
        var clientid = '262208347834-2c5bufdre43uuqs4hppea8c7k7r885g0.apps.googleusercontent.com'
        var url = "https://accounts.google.com/o/oauth2/v2/auth?scope=openid%20email%20profile&include_granted_scopes=true&redirect_uri=" + redirectionurl + "&response_type=code&client_id=" + clientid + "";  
        window.location.href = url;  
      },
      loginWithFacebook: async function() {
        window.location.href = "https://www.facebook.com/v10.0/dialog/oauth?scope=email&client_id=581414642797485&redirect_uri=https%3A%2F%2Fcompetitionmania.co.uk%2Fapi%2Faccount%2Flogin%2Ffacebook&state=1";
      }
    }
}
</script>


<style>

:root {
  --primary-color: #0071fe;
  --error-color: #f23648;
  --error-bg-color: #fddfe2;
  --success-color: #21a67a;
  --success-bg-color: #e0eee4;
}
.submit-btn {
  background: var(--primary-color);
  outline: none;
  border: none;
  color: #fff;
  font-size: 18px;
  padding: 10px 15px;
  display: block;
  width: 100%;
  border-radius: 7px;
  margin-top: 40px;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.submit-btn:hover {
  transform: scale(1.1);
}
</style>
